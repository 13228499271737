import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import Title from '../components/Title/Title'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import { addLectureRequest, getCountry, getGeneralData, homeGetter } from '../actions'
import { useTranslation } from 'react-i18next'
import useTranslate from '../components/useTranslate'
import { FLAG_BASE_URL } from '../configuration';
import MetaHead from '../components/MetaHead/MetaHead';

// FIRBASE
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

export default function Contact() {
    const [details, setDetails] = useState()
    const hasRendered = useRef(false);
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    // LANGUAGE
    const [lang, setLang] = useState("");
    const [show, setShow] = useState(false)
    const [placeName, setPlaceName] = useState('');
    const [currentLatLng, setCurrentLatLng] = useState({})
    const [flagImage, setFlagImage] = useState('')
    const [submitLoading, setSubmitLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const langSwitch = i18n?.language === "en";
    const token = localStorage.getItem('token');
    const csrfToken = localStorage.getItem('csrfToken');
    const device_id = localStorage.getItem('device_id')
    const ipAddress = localStorage.getItem('ip')
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    const Emirates = [
        { english: "Abu Dhabi", arabic: "أبو ظبي" },
        { english: "Dubai", arabic: "دبي" },
        { english: "Sharjah", arabic: "الشارقة" },
        { english: "Ajman", arabic: "عجمان" },
        { english: "Umm Al Quwain", arabic: "أم القيوين" },
        { english: "Ras Al Khaimah", arabic: "رأس الخيمة" },
        { english: "Fujairah", arabic: "الفجيرة" }
    ];

    const [inputs, setInputs] = useState({})
    const [errors, setErrors] = useState({})

    function changeHandler(field, value) {
        console.log(value)
        const regex = /^[a-zA-Z\s]*$/;
        if ((field === "beneficiary" || field === "coordinator_name") && !regex.test(value)) {
            return
        }
        setErrors(s => ({ ...s, [field]: '' }))
        setInputs(s => ({ ...s, [field]: value }))
    }



    // LOCATION

    useEffect(() => {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setCurrentLatLng(pos)

            }
        );

    }, []);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: currentLatLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
            setPlaceName(results[0].formatted_address);
        } else {
            console.error('Geocoder failed due to:', status);
        }
    });


    useEffect(() => {
        if (!hasRendered.current) {
            setupRecaptcha()

            hasRendered.current = true;
        }
    }, []);


    const setupRecaptcha = () => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'ReCaptcha', {
                'size': 'large',
                'theme': 'light',
                'callback': (response) => {
                    setRecaptchaVerified(true);
                },
                'expired-callback': () => {
                    setRecaptchaVerified(false);
                }
            });

            window.recaptchaVerifier.render().then(() => {
                console.log('reCAPTCHA rendered');
            }).catch((error) => {
                console.error('Error rendering reCAPTCHA:', error);
            });
        } catch (error) {
            console.error('Error setting up reCAPTCHA:', error);
        }
    };

    // END

    function submitHandler(e) {
        e.preventDefault();
        let errs = {}
        const phoneNumberRegex = /^\d{6,13}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!inputs.request_from) { errs.request_from = t('Required Field') }
        if (!inputs.request_to) { errs.request_to = t('Required Field') }
        if (!inputs.participants?.trim()) { errs.participants = t('Required Field') }
        if (inputs.participants < 0) { errs.participants = t('Must be a positive number') }
        if (!inputs.emirates?.english?.trim()) { errs.emirates = t('Required Field') }
        if (!inputs.beneficiary?.trim()) { errs.beneficiary = t('Required Field') }
        if (!inputs.coordinator_name?.trim()) { errs.coordinator_name = t('Required Field') }
        if (!inputs.email?.trim()) {
            errs.email = t('Required Field');
        } else if (!emailRegex.test(inputs.email)) {
            errs.email = t('Invalid email address');
        }
        if (!inputs.contact_number?.trim()) {
            errs.contact_number = t('Required Field');
        } else if (!phoneNumberRegex.test(inputs.contact_number)) {
            errs.contact_number = t('Invalid Phone number');
        }
        if (!recaptchaVerified) { errs.captcha = t('Please check Captcha') }
        setErrors(errs)
        if (Object.keys(errs).length > 0) return
        setSubmitLoading(true)
        addLectureRequest({
            request_from: inputs.request_from,
            request_to: inputs.request_to,
            participants: inputs.participants,
            emirates: inputs.emirates,
            beneficiary: inputs.beneficiary,
            coordinator_name: inputs.coordinator_name,
            email: inputs.email,
            ip: ipAddress,
            udid: device_id,
            device: 'Website',
            contact_number: inputs.contact_number,
            customer_contact_code: !flagImage?.code ? '+971' : flagImage?.code,
            precise_location: placeName
        }, (res) => {
            if (res.status) {
                setSubmitLoading(false)
                setInputs({})
                setShow(true)
            } else {
                setSubmitLoading(false)

            }

        })
    }


    useEffect(() => {
        getGeneralData((res) => {
            setDetails(res.data)
        })
    }, [])

    const preventMinus = (e) => {

        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        getCountry((res) => {
            setCountries(res)
        })
    }, [])

    const handleCountry = (e) => {
        const code = e?.code
        const selected = countries.find(item => item.code === code);
        setFlagImage(selected)
    }

    const handleClose = () => setShow(false);


    return (
        console.log(placeName, 'placeName'),
        <>
            <MetaHead
                title={details?.contact_meta_title}
                desc={details?.contact_meta_description}
                keyword={details?.contact_meta_keyword}
            />
            <section className='alameen__contact'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-tab-12">
                            <div className="left__grid" data-aos="fade-up" data-aos-duration="2000">
                                <div className="mainGrid">
                                    <Title title={langSwitch ? details?.contact_title : details?.contact_title_ar} />
                                    <p dangerouslySetInnerHTML={{ __html: langSwitch ? details?.contact_description : details?.contact_description_ar }}></p>
                                </div>
                                <div className="contactGrid" data-aos="fade-in">
                                    <div className="Item">
                                        <img src="assets/img/icons/contact-phone.svg" alt="" />
                                        <div className="contact">
                                            <a href={`tel:${details?.floating_contact_number}`}>
                                                <h5>{details?.floating_contact_number}</h5>
                                                <span>({t('Within state')})</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="Item">
                                        <img src="" alt="" />
                                        <div className="contact">
                                            <a href={`tel:+971 ${details?.floating_contact_number}`}>
                                                <h5>+971 {details?.floating_contact_number}</h5>
                                                <span>({t('Outside State')})</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="contactGrid social" data-aos="fade-in">
                                    <div className="Item social__networks">
                                        <p>{t('Social network')}</p>
                                        <ul>
                                            {details?.instagram && <li><a href={details?.instagram} target='_blank'><img src="assets/img/icons/insta.svg" alt="" /></a></li>}
                                            {details?.facebook && <li><a href={details?.facebook} target='_blank'><img src="assets/img/icons/fb.svg" alt="" /></a></li>}
                                            {details?.snapchat && <li><a href={details?.snapchat} target='_blank'><img src="assets/img/icons/snapchat.svg" style={{ height: '23px' }} alt="" /></a></li>}
                                            {details?.twitter && <li><a href={details?.twitter} target='_blank'><img src="assets/img/icons/x.svg" style={{ height: '20px' }} alt="" /></a></li>}
                                            {details?.linkedin && <li><a href={details?.linkedin} target='_blank'><img src="assets/img/icons/linkedin.svg" style={{ height: '20px' }} alt="" /></a></li>}
                                            {details?.whatsapp && <li><a href={`https://wa.me/${details?.whatsapp}`} target='_blank'><img src="assets/img/icons/whatsapp.svg" style={{ height: '20px' }} alt="" /></a></li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>




                        <div className="col-md-5 col-tab-12">
                            <div className="lecture__request" data-aos="fade-up" data-aos-duration="3000">
                                <h1>{t('Lecture Request')}</h1>
                                <form onSubmit={submitHandler}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form__icon">
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    selected={inputs?.request_from}
                                                    // onChange={(date) => setStartDate(date)}
                                                    placeholderText={t('From Date') + "*"}
                                                    onChange={(e) => {
                                                        changeHandler('request_from', e)
                                                        changeHandler('request_to', '')
                                                    }}
                                                    minDate={new Date()}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                />
                                                <img src="assets/img/icons/calendar.svg" alt="" />
                                                <small className='error'>{errors.request_from}</small>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form__icon">
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    selected={inputs?.request_to}
                                                    placeholderText={t('To Date') + "*"}
                                                    onChange={(e) => changeHandler('request_to', e)}
                                                    minDate={inputs?.request_from || new Date()}
                                                    className='shouldClose'
                                                    shouldCloseOnSelect={false}
                                                    onCalendarClose={() => {
                                                        // Force close only if a date is selected
                                                        if (inputs?.request_to) {
                                                            document.activeElement.blur();
                                                        }
                                                    }}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    // onChange={(date) => setStartDate(date)}
                                                    // minDate={inputs?.request_from || new Date()}
                                                    // disabled={inputs.request_from ? false : true}
                                                    // minDate={inputs.request_from}
                                                    minTime={new Date(inputs.request_to).setHours(0, 0, 0) === new Date(inputs.request_from).setHours(0, 0, 0) ? new Date(inputs.request_from) : new Date(new Date().setHours(0, 0, 0))}
                                                    maxTime={new Date().setHours(23, 59, 59)}
                                                />
                                                <img src="assets/img/icons/calendar.svg" alt="" />
                                                <small className='error'>{errors.request_to}</small>
                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="number" value={inputs?.participants || ''} placeholder={t('No Of Participants') + "*"} min="0" onKeyDown={preventMinus} onChange={(e) => changeHandler('participants', e.target.value)} className='form-control' />
                                                <small className='error'>{errors.participants}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Select options={Emirates}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    getOptionLabel={(e) =>langSwitch ?  e.english : e.arabic}
                                                    getOptionValue={(e) => e.english}
                                                    placeholder={t('Select Emirates') + "*"}
                                                    value={inputs?.emirates || ''}
                                                    onChange={(e) => changeHandler('emirates', e)} />
                                                <small className='error'>{errors.emirates}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={inputs?.beneficiary || ''} placeholder={t('Beneficiary') + "*"} className='form-control' maxLength={40} onChange={(e) => changeHandler('beneficiary', e.target.value)} />
                                                <small className='error'>{errors.beneficiary}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={inputs?.coordinator_name || ''} placeholder={t('Coordinator Name') + "*"} className='form-control' maxLength={40} onChange={(e) => changeHandler('coordinator_name', e.target.value)} />
                                                <small className='error'>{errors.coordinator_name}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={inputs?.email || ''} placeholder={t('Email') + "*"} className='form-control' onChange={(e) => changeHandler('email', e.target.value)} />
                                                <small className='error'>{errors.email}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {/* <div className="form-group">
                                        <input type="tel" value={inputs?.contact_number || ''} placeholder={t('Phone Number')} className='form-control' onChange={(e) => changeHandler('contact_number', e.target.value)} />
                                        <small className='error'>{errors.contact_number}</small>
                                    </div> */}

                                            <div className="form__number">
                                                <div className="flag__grid">
                                                    <Select options={countries}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        className='countrySelect'
                                                        getOptionLabel={(e) => e.code}
                                                        getOptionValue={(e) => e?.code}
                                                        defaultValue={+971}
                                                        placeholder='+971'
                                                        onChange={(e) => handleCountry(e)}
                                                    />
                                                    {
                                                        flagImage?.flag ? <img src={FLAG_BASE_URL + 'flags/' + flagImage?.flag} className='flag' alt="" /> :
                                                            <img src={'assets/img/icons/united_arab_emirates.svg'} className='flag' alt="" />
                                                    }

                                                    <input
                                                        type="number"
                                                        value={inputs?.contact_number || ''}
                                                        placeholder={t('Phone Number') + "*"}
                                                        className="form-control"
                                                        min="0"
                                                        onKeyDown={preventMinus}
                                                        onChange={(e) => changeHandler('contact_number', e.target.value)}
                                                    />

                                                </div>
                                                <small className='error'>{errors.contact_number}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div id="ReCaptcha"></div>
                                            <small className='error'>{errors.captcha}</small>
                                            <div className="text-center mt-3">
                                                <button disabled={submitLoading} className='btn btn-primary' type='submit'>{t('Submit')}
                                                    {submitLoading ? <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner> : <img src="assets/img/icons/arrow-right-red.svg" alt="" />}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* {Modal for submit} */}


                        <Modal show={show} backdrop="static" size="lg" centered onHide={handleClose}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <div className="report__success">
                                <div className="icon"><img src="assets/img/icons/success.svg" alt="" /></div>
                                <h2>{t('Thank you Lecture Submitted successfully')}</h2>
                                <p>{t('Your Lecture submission was successful')}. {t('Our team will now review and address your concerns promptly')}.</p>
                            </div>
                        </Modal>

                    </div>
                </div>
            </section>
        </>

    )
}
