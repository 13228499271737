import React, { useEffect, useState } from 'react'
import './Header.scss'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import VolunteerWithUs from '../Modal/VolunteerWithUs';
import { Modal } from 'react-bootstrap';
import Signup from '../Modal/Signup';
import ReportIncident from '../Modal/ReportIncident';
import { getGeneralData, getTexts } from '../../actions';
import useTranslate from '../useTranslate';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';

export default function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslate()
  const { i18n } = useTranslation()

  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false)
  const [show, setShow] = useState(false);
  const [general, setGeneral] = useState({});
  const [signupshow, setSignupshow] = useState(false);
  const [logoutshow, setLogoutshow] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSignupClose = () => setSignupshow(false);
  const handleLogoutClose = () => setLogoutshow(false);
  const handleSignup = () => setSignupshow(true)
  const handleLogout = () => setLogoutshow(true)
  const [lang, setLang] = useState(localStorage.getItem('lang'))
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false)


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setScrolling(true);
        document.body.classList.add('scrolled');
      } else {
        setScrolling(false);
        document.body.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //MENU

  const handleMenu = () => {
    const newMenuOpenState = !menuOpen;
    setmenuOpen(newMenuOpenState);
    if (newMenuOpenState) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }

  const handleMenuClose = () => {
    const newMenuOpenState = false;
    setmenuOpen(newMenuOpenState);
    if (newMenuOpenState) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }


  useEffect(() => {
    handleMenuClose()
  }, [location.pathname])




  // DARK MODE

  useEffect(() => {
    const savedTheme = localStorage.getItem('dark-mode');
    if (savedTheme === 'enabled') {
      document.body.classList.add('dark-mode');
      setIsDarkMode(true);
    }
  }, []);

  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('dark-mode', 'disabled');
    } else {
      document.body.classList.add('dark-mode');
      localStorage.setItem('dark-mode', 'enabled');
    }
    setIsDarkMode(!isDarkMode);
  };

  // LANGUAGE
  const langSwitch = i18n?.language === 'en'
  const defaultLang = localStorage.getItem('localLang')
  useEffect(() => {
    if (i18n?.language == 'ar' || defaultLang == 'en-US') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [langSwitch])

  function langChangeHandler(value) {
    localStorage.setItem('lang', value)
    i18n.changeLanguage(value);
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setUser(null)
    }
  }, [signupshow, logoutshow]);

  const logOut = () => {
    localStorage.removeItem('user')
    setLogoutshow(false)
    navigate('/')
    toast.success(t('logout-successfully'))
  }

  useEffect(() => {
    setLoader(true)
    getGeneralData((res) => {
      setTimeout(() => {
        setLoader(false)
      }, 500);
      setGeneral(res.data)
    })
  }, [location?.pathname])


  return (
    loader ? <Loader /> :
      <header className="header d-flex align-items-center fixed-top rsuite-wrapper">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          {
            !menuOpen && <Link to="/" className="logo d-flex align-items-center me-auto">
              <img src="/assets/img/logo.svg" alt="" />
            </Link>
          }


          <nav id="navmenu" className="navmenu">
            <ul>
              <li><NavLink to="/">{t('Home')}</NavLink></li>
              <li><NavLink to="/about">{t('About')}</NavLink></li>
              <li><NavLink to="/updates">{t('Updates')}</NavLink></li>
              <li><NavLink to="/contact">{t('Contact')}</NavLink></li>
              {user && <li><NavLink to="/reports">{t('Reports')}</NavLink></li>}
              {
                user ? <li onClick={handleLogout}><a>{t('Logout')}</a></li> :
                  <li onClick={handleSignup}><a>{t('Login')}</a></li>
              }

            </ul>
            {
              menuOpen ? <img src="/assets/img/icons/close.svg" alt="" className='mobile-nav-toggle d-xl-none close' onClick={handleMenu} /> :
                <img src="/assets/img/icons/menu.svg" alt="" className='mobile-nav-toggle d-xl-none open' onClick={handleMenu} />
            }

            {/* <i class={` bi ${menuOpen ? 'bi-x' : 'bi-list'}`} > </i> */}
            <div className={user ? 'contact logged' : 'contact'}>
              {
                location?.pathname == '/' ? <div className="grid volunteer" onClick={handleShow}>
                  <p
                    dangerouslySetInnerHTML={{ __html: t('Volunteer With Us').replace('\n', '<br />') }}
                  ></p>
                  <img src="/assets/img/icons/arrow-up-red.svg" alt="" />
                </div> :
                  <div className="grid reportIncident" onClick={handleShow}>
                    <p
                      dangerouslySetInnerHTML={{ __html: t('Report Incident').replace('\n', '<br />') }}
                    ></p><img src="/assets/img/icons/arrow-up-red.svg" alt="" />
                  </div>
              }

              <div className="grid">
                <p>{t('Call Us')}<img src="/assets/img/icons/arrow-up-red.svg" alt="" /></p>
                <h4><a href={`tel:${general?.floating_contact_number}`}>{general?.floating_contact_number}</a></h4>
              </div>
              <div className="language">
                <button className='btn btn-sm btn-mode mode' onClick={toggleDarkMode}>
                  <img src='/assets/img/icons/mode.svg' className='dark-mode' />
                  <img src='/assets/img/icons/light-mode.svg' className='light-mode' />
                </button>
                <h6 className={langSwitch ? 'active' : ""} onClick={() => langChangeHandler('en')}>En</h6>
                <h6 className={!langSwitch ? 'active' : ""} onClick={() => langChangeHandler('ar')}>Ar</h6>

              </div>
            </div>
          </nav>

        </div>

        {/* Volunteer With Us Modal */}
        <Modal show={show} backdrop="static" size="lg" centered onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {
                location.pathname == '/' ? <>{t('Volunteer With Us')} <img src="/assets/img/icons/arrow-up-red.svg" alt="" /></> :
                  <><img src="/assets/img/icons/report-incident.svg" alt="" /> {t('Submit Incident')} </>
              }

            </Modal.Title>
          </Modal.Header>
          {
            location.pathname == '/' ? <VolunteerWithUs setShow={setShow} /> : <ReportIncident setShow={setShow} />
          }

        </Modal>

        {/* Signup With Us Modal */}
        <Modal show={signupshow} backdrop="static" size="md" centered onHide={handleSignupClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('sign_up')}
            </Modal.Title>
          </Modal.Header>
          <Signup setSignupshow={setSignupshow} />
        </Modal>


        {/* LOGOUT */}


        <Modal show={logoutshow} backdrop="static" size="md" centered onHide={handleLogoutClose}>
          <div className="logoutModal">
            <h4>{t('Logout')}</h4>
            <p>{t('Are you sure you want to logout?')}</p>
            <button className='btn btn-primary' onClick={logOut}>{t('Yes')}</button>
            <button className='btn btn-secondary' onClick={handleLogoutClose}>{t('Cancel')}</button>
          </div>
        </Modal>
      </header>
  )
}
