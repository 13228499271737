import axios from "axios";


export function generateSlug(title) {
  return title
    ?.toLowerCase()
    ?.replace(/[^a-z0-9]+/g, '-')
    ?.replace(/(^-|-$)+/g, '');
}


export async function fileChunksUploder({ file, uploader, track_id, emirates_id, fileKey, crmDestinationpath }, cb) {
  let chunkSize = 5 * 1024 * 1024
  const totalChunks = Math.ceil(file.size / chunkSize);
  const filename = file.name
  console.log({ file, totalChunks, size: file.size, chunkSize })
  let uploads = []
  for (let i = 0; i < totalChunks; i++) {
    const chunkIndex = i
    const mimeType = file.type || 'application/octet-stream'
    const start = chunkIndex * chunkSize;
    const end = Math.min(file.size, start + chunkSize);
    const chunk = file.slice(start, end, mimeType);
    const formdata = new FormData();
    formdata.append('track_id', track_id)
    formdata.append('emirates_id', emirates_id)
    formdata.append('fileKey', fileKey)
    formdata.append('crmDestinationpath', crmDestinationpath)
    formdata.append('file', file);
    formdata.append('chunk', chunk); //filename
    formdata.append('chunkIndex', chunkIndex.toString());
    formdata.append('totalChunks', totalChunks.toString());
    formdata.append('fileName', filename);
    uploads.push(uploader(formdata))
    console.log({ start, end, chunk })
  }
  try {
    Promise.all(uploads)
      .then(res => {
        console.log({ then_res: res.find(f => f.data.data) })
        cb && cb(true, res.find(f => f.data.data))
      }).catch(res => {
        console.log({ catch_res: res[0] })
        // cb && cb(false, res[0])
      })
  } catch (error) {
    console.log(error)
  }
}