import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_BASE_URL } from '../../configuration'
import { API_IMAGE_URL } from '../../configuration'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import useTranslate from '../useTranslate';
import { generateSlug } from '../../utils/Utils';
import { getEvents } from '../../actions';

function Events({setEventsCount}) {
  const { i18n } = useTranslation();
  const { t } = useTranslate();
  const [page, setPage] = useState(0)
  const [events, setEvents] = useState([])
  const [pageCount, setPageCount] = useState()
  const [remainingCount, setRemainingCount] = useState()
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  const handlePageIncrement=()=>{
    setPage(page+1)
  }

  useEffect(() => {
    const formdata = new FormData()
    formdata.append('perPage', 4)
    formdata.append('page', page)
    // formdata.append('home_page', 1)
    getEvents(formdata, (res) => {
        setEvents([...events, ...res.data]);
        setPageCount(res?.total_count)
        setRemainingCount(res?.remaining_count)
        setEventsCount(res?.total_count)
    })
  }, [page])
  return (
    <section className="latest_updates pt-3">
      <div className="container">
        <div className="row">
          {
            events?.map((ele, i) => {
              return (
                <div className="col-md-6">
                  <Link to={`/updates/event-details/${ele?._id}/${generateSlug(ele?.title)}`}>
                    <div className="newsEventGrid">
                      <div className="tag">{t('Events')}</div>
                      <img src={API_IMAGE_URL + ele?.file_path + ele?.image} alt="" />
                      <div className="content">
                        <h3>{langSwitch ? ele?.title : ele?.title_ar}</h3>
                        <h6><span>{moment(ele?.event_date).format('MMMM DD, yyyy')}</span> | <span>{moment(ele?.event_date).format('hh : mm A')}</span> | <span>{langSwitch ? ele?.location : ele?.location_ar }</span></h6>
                        <p dangerouslySetInnerHTML={{ __html: langSwitch ? ele?.content : ele?.content_ar}}></p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          }

        </div>
        {
          pageCount > 4 && !remainingCount == 0 && <div className="text-center mt-4"> <button className='btn btn-primary' onClick={handlePageIncrement}>{t('Load More')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /></button></div>
        }
        
      </div>
    </section>
  )
}

export default Events