import React, { useEffect, useState } from 'react'
import NewsRecent from '../components/Updates/NewsRecent'
import { newsDetails } from '../actions';
import { useTranslation } from 'react-i18next';
import useTranslate from '../components/useTranslate';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { API_IMAGE_URL } from '../configuration';
import ImageComponent from '../components/ImageComponent/ImageComponent';
import MetaHead from '../components/MetaHead/MetaHead';
export default function NewsDetails() {
  const { i18n } = useTranslation();
  const { t } = useTranslate();
  const { id } = useParams();
  const [details, setDetails] = useState({})
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    newsDetails(id, (res) => {
      setDetails(res.data)
    })
  }, [id])

  return (
    <>
         <MetaHead
    title={details?.title}
    desc={details.content}
    // keyword={details?.meta_keyword}
  />
   <section className='detail__page'>
      <div className="container">
        <div className="title">
          <h6>{t('News')}</h6>
          <h1>{langSwitch ? details?.title : details?.title_ar}</h1>
          <h5><span>{moment(details?.news_date).format('MMMM DD, yyyy')}</span> | <span>{details?.location_en}</span></h5>
        </div>
        <div className="details">
        <div className="slider">
        <ImageComponent src={API_IMAGE_URL + details?.file_path + details?.image} className='w-100' />
        </div>
          <p dangerouslySetInnerHTML={{ __html: langSwitch ? details?.content : details?.content_ar }}></p>
        </div>
        <NewsRecent id={id} />
      </div>
    </section>
    </>
   
  )
}
