export const API_BASE_URL = 'https://al-ameen-cms.shtdevops.xyz/'
export const API_IMAGE_URL = 'https://al-ameen-cms.shtdevops.xyz'
export const API_BASE_URL_MEDIA_SERVER = 'https://al-ameen-media.shtdevops.xyz/'
export const CRM_API_BASE_URL = 'https://al-ameen-api.shtdevops.xyz/web/'

export const FLAG_BASE_URL = 'https://alameen-api.shtdevops.xyz/'
export const CAPTCHA_KEY = '6LcofQ4qAAAAANs1oS0Lf1aCABTeMg8U-ooPF5XV'
export const API_INSTA_TOKEN = "IGQWROcWIyUEg1RnJ2S0o5a09DcC1QMjFQQk5Ra0c3bS1WcEZAFZAF9WMjVqSGRuOWlZAUndaNDh6LUk2MXdja1phTENpMFNQeDctd2RINFJOdWNYZAVc2WFl6VmltZAzBJdkdFR0UtaTFaMVhhelBxTHRISkdnOE9mVkEZD"


//server
// let base_url=`${window.location.origin}`;
// let base_url=`https://alameen.gov.ae`;
// export const CAPTCHA_KEY = '6Lf1IWUqAAAAAIzr7YljjOUJphE0hw8QA-ktQM79'
// export const API_INSTA_TOKEN = "IGQWRPU2RaYzdTSUcyNExKdXNTZAHJzVDhub2ZAoYmVMMHBJYVdROHpQOHF0b1EtcGFWaVVjYzRRa3pjeTBQXzRQZA1pUM1NlZAmVmbW5PVnFUaG5URHhxSU9ZAS3BCcmgxdWI2ZA0J5Ri0zUE1NSDVKVWoybzBnU2F4TzAZD"
//  export const API_BASE_URL = `${base_url}/cms/`
// export const API_BASE_URL_MEDIA_SERVER = `${base_url}/`
// export const API_IMAGE_URL = `${base_url}/filepath/uploads`
// export const CRM_API_BASE_URL = `${base_url}/crm-backend/web/`
// export const FLAG_BASE_URL = `${base_url}/filepath/uploads/`